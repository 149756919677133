/* eslint-disable default-case */
import React, { useEffect, useState, useReducer, useRef } from 'react';
import {
   Input, Table, Typography, Space, Tooltip, Button, Select, Row, Col,
   Modal, Card, InputNumber, Image, Spin, Popconfirm, Divider
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import SimpleBarReact from "simplebar-react";
import axios from 'axios';
import uuid from 'react-uuid';
// import { at } from 'lodash';
import Utils from '../../../misc/Utils';
// import Enums from '../../../misc/Enums';
import Split from 'react-split';
import TextArea from 'antd/lib/input/TextArea';
import Iframe from 'react-iframe';
// import AllPagesPDFViewer from '../../../components/pdf-viewer/all-pages';
import './Submissions.css'

// import RenderPDFViewer from '../../../components/iframe-viewer/pdfViewer';
// import { PDFReader } from 'reactjs-pdf-reader';
// import { RenderPDFReader } from '../../../components/pdfreader/RenderPDFReader';
import ReactPlayer from 'react-player'
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Link } = Typography;
// const { Option } = Select;

var pdfFile = '';

export default function Submissions(props) {
   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [submissionList, setSubmissionList] = useState([]);
   const [submissionListLoading, setSubmissionListLoading] = useState(false);
   const [checkEvaluateModalVisible, setCheckEvaluateModalVisible] = useState(false);
   // const [homeworkTitle, setHomeworkTitle] = useState(props.homeworkTitle);
   const [studentName, setStudentName] = useState("");
   const [submittedDocument, setSubmittedDocument] = useState(null);
   const [documentLink, setDocumentLink] = useState(null);

   const [evaluationData, setEvaluationData] = useState({
      Id: "",
      Homework_submitted_id: "",
      Score: null,
      Remarks: ""
   })
   const [score, setScore] = useState(0);
   const [remarks, setRemarks] = useState("");

   const [evaluating, setEvaluating] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      pdfFile = '';

      getStudentSubmissions(props.homeworkID, props.levelID);
   }, [props.homeworkID, props.levelID]);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const SubmissionListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'student_id',
         key: 'student_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'section_id',
         key: 'section_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'homework_id',
         key: 'homework_id',
         hidden: true,
      },
      {
         title: 'Document',
         key: 'document',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'evaluation_id',
         key: 'evaluation_id',
         hidden: true,
      },
      {
         title: 'Student',
         key: 'student_name',
         dataIndex: 'student_name',
         sorter: (a, b) => { return (a.student_name === null ? '' : a.student_name).localeCompare(b.student_name === null ? '' : b.student_name) },
         ...getColumnSearchProps('student_name'),
         ellipsis: {
            showTitle: true,
         },
         render: student_name => (
            <Tooltip placement="right" title={student_name}>
               <span style={{ cursor: "default" }}>{student_name}</span>
            </Tooltip>
         ),
         width: '16%'
      },
      {
         title: 'Section',
         key: 'section_name',
         dataIndex: 'section_name',
         sorter: (a, b) => { return (a.section_name === null ? '' : a.section_name).localeCompare(b.section_name === null ? '' : b.section_name) },
         ...getColumnSearchProps('section_name'),
         ellipsis: {
            showTitle: true,
         },
         render: section_name => (
            <Tooltip placement="right" title={section_name}>
               <span style={{ cursor: "default" }}>{section_name}</span>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Student Message',
         key: 'message',
         dataIndex: 'message',
         sorter: (a, b) => { return (a.message === null ? '' : a.message).localeCompare(b.message === null ? '' : b.message) },
         width: '19%'
      },
      {
         title: 'Document Link',
         key: 'url_link',
         hidden: true,
         // dataIndex:'url_link',
         // width:'10%'
      },
      {
         title: 'Date Submitted',
         key: 'created_at',
         dataIndex: 'created_at',
         sorter: (a, b) => { return (a.created_at === null ? '' : a.created_at).localeCompare(b.created_at === null ? '' : b.created_at) },
         width: '12%'
      },
      {
         title: 'Score',
         key: 'score',
         dataIndex: 'score',
         sorter: (a, b) => a.score - b.score,
         render: score => (
            <span>{score == 0 ? '' : score}</span>
         ),
         width: '6%'
      },
      {
         title: 'Remarks',
         key: 'remarks',
         dataIndex: 'remarks',
         width: '19%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               {payload.created_at
                  ?
                  <Space size="small">
                     {// payload.evaluation_id === null &&
                        <Tooltip placement="top" title='Evaluate'>
                           <AntDIcons.FormOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                              onClick={() => handleEvaluate(payload.id, payload.student_name, payload.document, payload.url_link, payload.evaluation_id, payload.score, payload.remarks)}
                           />
                        </Tooltip>
                     }
                     {payload.evaluation_id !== null &&
                        <Tooltip placement="top" title='Remove Evaluation'>
                           <Popconfirm
                              title="Are you sure you want to remove the evaluation?"
                              onConfirm={() => handleRemoveEvaluation(payload.evaluation_id)}
                              okText="Yes"
                              cancelText="No"
                           >
                              <AntDIcons.DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                           </Popconfirm>
                        </Tooltip>
                     }
                  </Space>
                  :
                  <></>
               }
            </>
         },
         width: '6%'
      },
   ].filter(item => !item.hidden);

   const getStudentSubmissions = (homework_id, level) => {
      setSubmissionListLoading(true);

      var url = `${process.env.REACT_APP_API_HOMEWORK}/studentlist?homework_id=${homework_id}&level=${level}`;
      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setSubmissionList(data);

            setSubmissionListLoading(false);
         }
      }, (error) => {
         setSubmissionListLoading(false);
         console.log(error.message);
      });
   }

   const handleEvaluate = (id, student_name, document, document_link, e_id, score, remarks) => {
      pdfFile = '';

      setDocumentLink(document_link);
      setSubmittedDocument(document);
      setStudentName(student_name);
      setScore(score);
      setRemarks(remarks);

      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      var evaluation_id = "homework_evaluation_" + o2o_mode + "_" + uuid();

      var initialEvaluationData = {
         Id: e_id === '' || e_id === null ? evaluation_id : e_id,
         Homework_submitted_id: id,
         Score: null,
         Remarks: null
      }

      // setScore(null);
      // setRemarks(null);

      setEvaluationData(initialEvaluationData);
      setCheckEvaluateModalVisible(true);
   }

   const handleSaveEvaluation = () => {
      if (score !== 0 || remarks !== '') {
         setEvaluating(true);

         var url = `${process.env.REACT_APP_API_HOMEWORK}/addevaluation`;

         var finalEvaluationData = {
            Id: evaluationData.Id,
            Homework_submitted_id: evaluationData.Homework_submitted_id,
            // Homework_id: evaluationData.Homework_id,
            // Student_id: evaluationData.Student_id,
            Score: score,
            Remarks: remarks,
         }

         axios.post(url, finalEvaluationData).then(response => {
            if (response.data) {
               setEvaluating(false);

               Modal.success({
                  title: 'Homework',
                  content: 'Homework evaluation added.',
                  centered: true,
                  onOk: () => { setCheckEvaluateModalVisible(false); },
               });

               getStudentSubmissions(props.homeworkID, props.levelID);
            }
         }, (error) => {
            setEvaluating(false);
            Modal.error({
               title: 'Homework',
               content: 'Homework evaluation failed.',
               centered: true,
            });
         });
      }
   }

   const handleRemoveEvaluation = (id) => {
      setEvaluating(true);
      var url = `${process.env.REACT_APP_API_HOMEWORK}/removeevaluation?id=${id}`;

      axios.post(url).then(response => {
         if (response.data) {
            setEvaluating(false);

            Modal.success({
               title: 'Homework',
               content: 'Homework evaluation Removed.',
               centered: true,
            });

            getStudentSubmissions(props.homeworkID, props.levelID);
         }
      }, (error) => {
         setEvaluating(false);
         Modal.error({
            title: 'Homework',
            content: 'Homework evaluation removal failed.',
            centered: true,
         });
      });
   }

   const handleCancelEvaluation = () => {
      setCheckEvaluateModalVisible(false);
   }

   function sPDFFile(newPDF) {
      pdfFile = newPDF;
      return true;
   }

   const renderPreviewer = (document, document_link) => {
      if (document !== null && document !== '') {
         var re = /(?:\.([^.]+))?$/;
         var ext = re.exec(document.toLowerCase());

         var subdomain = localStorage.getItem("lms-subdomain");
         var url = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${process.env.REACT_APP_S3_HOMEWORKS_STUDENTS_PATH}/${document}`;

         switch (ext[1]) {
            case "pdf":
               return <>
                  <div style={{ width: "100%", height: "calc(100vh - 185px)" }}>
                     <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                        <Viewer
                           pageLayout={{
                              transformSize: ({ size }) => ({
                                 height: size.height + 30,
                                 width: size.width + 30,
                              }),
                           }}
                           fileUrl={url}
                           defaultScale={SpecialZoomLevel.PageWidth}
                           initialPage={0}
                        />
                     </Worker>
                  </div>
                  {/* <RenderPDFReader url={url} height={"calc(100vh - 185px)"} /> */}
                  {/* <RenderPDFViewer url={url} height={"calc(100vh - 185px)"} showdownload={true} showprint={true} showpagecontrols={true} showfullscreen={true} load={pdfFile !== document ? sPDFFile(document) : false} /> */}
               </>

            case "doc":
            case "docx":
            case "xls":
            case "xlsx":
            case "ppt":
            case "pptx":
               return <>
                  <Iframe
                     id="msdoc-iframe"
                     title="msdoc-iframe"
                     src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                     frameBorder="0"
                     height='100%'
                     width='100%'
                  // loading='eager'
                  // sandbox='allow-scripts allow-modal'
                  />
               </>

            case "png": case "jpg": case "jpeg":
               return <>
                  {/* <SimpleBarReact style={{height:"calc(100vh - 200px)"}}> */}
                  <Image
                     src={url}
                  // width={"100%"}
                  // height={"100%"}
                  />
                  {/* </SimpleBarReact> */}
               </>

            case "mp4": case "avi": case "mp3": case "wav":
               return <>
                  <ReactPlayer
                     controls={true}
                     width='100%'
                     height='100%'
                     url={url.replace(/ /g, '+').replace(/#/g, '%23')}
                  />
               </>
         }
      } else {
         if (document_link !== null && documentLink !== '')
            return <>
               <div className='preview'>
                  <a href={`${document_link}`} title="Click here to view the document" target={"_blank"}>Click here to view the document</a>
               </div>
            </>
         else
            return <></>
      }
   }

   return (
      <>

         <Modal
            title={" Evaluate Homework (" + props.homeworkTitle + ")"}
            centered
            destroyOnClose={true}
            open={checkEvaluateModalVisible}
            okText={"Save"}
            onOk={() => handleSaveEvaluation()}
            onCancel={() => handleCancelEvaluation()}
            // width={window.innerWidth - 400}
            width='75%'
            cancelButtonProps={{ shape: "round" }}
            okButtonProps={{ shape: "round" }}
         >
            {/* <Row>
            <Col span={16}>
               <label style={{fontWeight:"bold"}}>Preview</label>
               <div className='essay_list_container' style={{background:"#fff"}}>
                  <SimpleBarReact style={{width: "100%"}}>
                     
                  </SimpleBarReact>
               </div>
            </Col>
            <Col span={8}>
               <label style={{fontWeight:"bold"}}>Evaluate</label>
               <div className='student_list_container' style={{background:"#fff"}}>
               <Card
                  title="Card title"
                  bordered={true}
                  style={{width: "100%", padding:"10px"}}
               >
                  <p>Card content</p>
                  <p>Card content</p>
                  <p>Card content</p>
               </Card>
               </div>
            </Col>
         </Row> */}
            <Spin spinning={evaluating} tip="Adding evaluation, please wait...">
               <Row gutter={12}>
                  {/* <Col span={24}> */}
                  {/* <Split
                        sizes={[70, 30]}
                        direction="horizontal"
                        cursor="col-resize"
                        className="split-flex-modal"
                        style={{ height: "calc(100vh-200px" }}
                     >
                        <div style={{ width: `70%`, overflow: `auto` }}>
                           <label style={{ fontWeight: "bold" }}>Preview</label>
                           <div className='essay_list_container' style={{ background: "#fff" }}>
                              {renderPreviewer(submittedDocument, documentLink)}
                           </div>
                        </div>
                        <div style={{ width: `30%`, overflow: "auto" }}>
                           <label style={{ fontWeight: "bold" }}>Evaluate</label>
                           <div className='student_list_container' style={{ background: "#fff" }}>
                              <SimpleBarReact style={{ height: "calc(100vh - 200px)" }}>
                                 <Card
                                    title={studentName}
                                    bordered={false}
                                    style={{ width: "100%", padding: "10px" }}
                                 >
                                    <Space direction='vertical' style={{ width: "100%" }}>
                                       <label>Score</label>
                                       <InputNumber
                                          min={0}
                                          max={100}
                                          style={{ borderRadius: "7px", width: "100%" }}
                                          onChange={(val) => setScore(val)}
                                          defaultValue={score}
                                       />
                                       <label>Remarks</label>
                                       <TextArea
                                          allowClear
                                          rows={8}
                                          onBlur={(event) => setRemarks(event.target.value)}
                                          defaultValue={remarks}
                                       />
                                    </Space>
                                 </Card>
                              </SimpleBarReact>
                           </div>
                        </div>
                     </Split> */}
                  <Col xs={24} md={16} lg={16}>
                     <div style={{ overflow: `auto`, borderRadius: '7px' }}>
                        <label style={{ fontWeight: "bold" }}>Preview</label>
                        <div className='modal-document-viewer' style={{ background: "#fff", height: 'calc(100vh - 185px)', margin: '0px' }}>
                           {renderPreviewer(submittedDocument, documentLink)}
                        </div>
                     </div>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                     <div style={{ overflow: "auto", borderRadius: '7px' }}>
                        <label style={{ fontWeight: "bold" }}>Evaluate</label>
                        <div className='student_list_container' style={{ background: "#fff" }}>
                           <SimpleBarReact style={{ height: "calc(100vh - 185px)" }}>
                              <Card
                                 title={<Text ellipsis>{studentName}</Text>}
                                 bordered={false}
                                 style={{ width: "100%", padding: "10px" }}
                              >
                                 <Space direction='vertical' style={{ width: "100%" }}>
                                    <label>Score</label>
                                    <InputNumber
                                       min={0}
                                       max={100}
                                       style={{ borderRadius: "7px", width: "100%" }}
                                       onChange={(val) => setScore(val)}
                                       defaultValue={score}
                                    />
                                    <label>Remarks</label>
                                    <TextArea
                                       allowClear
                                       rows={8}
                                       onBlur={(event) => setRemarks(event.target.value)}
                                       defaultValue={remarks}
                                    />
                                 </Space>
                              </Card>
                           </SimpleBarReact>
                        </div>
                     </div>
                  </Col>
                  {/* </Col> */}
               </Row>
            </Spin>
         </Modal>

         <Row gutter={12} style={{ minWidth: "400px", overflow: "auto" }}>
            <Col span={24}>
               <div className="dashboard-item-inner3">
                  <Table
                     rowKey={'uid'}
                     loading={submissionListLoading}
                     size='small'
                     columns={SubmissionListColumns}
                     dataSource={submissionList}
                     pagination={false}
                     scroll={{
                        y: 'calc(100svh - 20rem)',
                        x: '1000px',
                     }}
                     footer={() => 'Total Records: ' + submissionList.length}
                  />
               </div>
            </Col>
         </Row>
      </>
   );
}
/* eslint-disable default-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
// import SimpleBarReact from "simplebar-react";
// import Countdown from 'react-countdown';
import { QuestionCircleOutlined, EyeOutlined } from '@ant-design/icons';
import '../../../node_modules/simplebar/src/simplebar.css';
import './MasteryAssessment.css';
import './MasteryAnswer.css';

import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import { Button, Typography, Popconfirm, Modal, Carousel, Row, Col, Input, Breadcrumb } from 'antd';

// import Split from 'react-split';
// import './SplitPanel.css';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { useSelector } from 'react-redux';
import platform from 'platform';
import uuid from 'react-uuid';
import StickyBox from "react-sticky-box";

const { Text } = Typography;

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];

export function MasteryAnswerManual(props) {
   const carouselRef = createRef();
   const location = useLocation();
   const navigate = useNavigate();
   const assessmentInfo = location.state.assInfo;
   const assignInfo = location.state.assignInfo;
   const assessmentType = location.state.type;

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const masteryrec = useSelector(state => state.masteryrec);

   const origin = location.state.origin;
   const orig_question_sheet = assessmentInfo.Question_sheet;

   const [isSorted, setIsSorted] = useState(false);

   const [answerSheetData, setAnswerSheetData] = useState(() => {
      // localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_my_sequence");
      const sequence_local = JSON.parse(localStorage.getItem(user_id + "_" + assessmentInfo.Id + "_my_sequence"));
      const tmpAssInfo = orig_question_sheet;

      //-- If sequence is already created then sort by sequence else randomize questions
      if (sequence_local !== null) {
         for (var i = 0; i < sequence_local.length; i++) {
            tmpAssInfo[i].sequence_no = sequence_local[i];
         }

         // tmpAssInfo.sort((a, b) => {
         //    return a.sequence_no - b.sequence_no;
         // });
      } else {
         if (assignInfo.randomize_questions === 1) {
            var sequence_numbers = null;
            sequence_numbers = Utils.generateSequenceNumbers(tmpAssInfo.length);
            localStorage.setItem(user_id + "_" + assessmentInfo.Id + "_my_sequence", JSON.stringify(sequence_numbers));

            //-- Add sequence number to every objects
            for (var ii = 0; ii < sequence_numbers.length; ii++) {
               tmpAssInfo[ii].sequence_no = sequence_numbers[ii];
            }

            //-- Execute randomization of question
            // tmpAssInfo.sort((a, b) => {
            //    return a.sequence_no - b.sequence_no;
            // });
         }
      }

      return tmpAssInfo;
   });

   const [answerData, setAnswerData] = useState(() => {
      // localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_myanswers");
      var ansLocal = JSON.parse(localStorage.getItem(user_id + "_" + assessmentInfo.Id + "_myanswers"));
      if (ansLocal !== null) {
         // ansLocal.sort((a, b) => {
         //    return a.sequence_no - b.sequence_no;
         // });
         return ansLocal;
      } else {
         return initializeAnswerData(orig_question_sheet)
      }
   });

   const [startTime, setStartTime] = useState(() => {
      var timeStarted = JSON.parse(localStorage.getItem(user_id + "_" + assessmentInfo.Id + "_timestarted"));
      if (timeStarted !== null) {
         var val = timeStarted;
         return val;
      } else {
         var val = Date.now();
         localStorage.setItem(user_id + "_" + assessmentInfo.Id + "_timestarted", JSON.stringify(val));
         return Date.now();
      }
   });

   const [isSubmitted, setIsSubmitted] = useState(false);
   const [questionIndex, setQuestionIndex] = useState(1);
   const [instruction, setInstruction] = useState(null);
   const [openInstruction, setOpenInstruction] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";

      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);      
      // localStorage.clear();

      //-- Sort question and answer template by assigned sequence
      answerSheetData.sort((a, b) => {
         return a.sequence_no - b.sequence_no;
      });

      answerData.sort((a, b) => {
         return a.sequence_no - b.sequence_no;
      });

      setIsSorted(true);

      var tmpInstruction = getInstruction(assessmentType);

      if (tmpInstruction !== '' && tmpInstruction !== null) {
         setInstruction(tmpInstruction);
         setOpenInstruction(true);
      }

   }, [assignInfo.id]);

   function initializeAnswerData(data) {
      let ansData = [];
      let tmpdata = {};
      var sequence_numbers = null;

      // //-- Randomize question if enabled
      if (assignInfo.randomize_questions === 1) {
         sequence_numbers = JSON.parse(localStorage.getItem(user_id + "_" + assessmentInfo.Id + "_my_sequence"));
      }

      data.map(({ id, type, option_labels }, idx) => {
         // if (data[idx].type.includes("section")) {
         //    tmpdata = {
         //       "id": id,
         //       "type": type,
         //       "answer": data[idx].correct,
         //    }
         // } else 
         if (data[idx].type.includes("multiple")) {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": setDefaultAnswers(type, option_labels.length),
               "idx": idx,
               "sequence_no": sequence_numbers !== null ? sequence_numbers[idx] : idx,
               // "considered":"0",
            }
         } else if (data[idx].type === "short_answer") {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": [""],
               "considered": "0",
               "idx": idx,
               "sequence_no": sequence_numbers !== null ? sequence_numbers[idx] : idx,
            }
         } else if (data[idx].type.includes("matching_type")) {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": setDefaultAnswers(type, option_labels.length),
               "considered": "0",
               "idx": idx,
               "sequence_no": sequence_numbers !== null ? sequence_numbers[idx] : idx,
            }
         }
         else {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": [""],
               "idx": idx,
               "sequence_no": sequence_numbers !== null ? sequence_numbers[idx] : idx,
            }
         }

         ansData.push(tmpdata);
      });

      // ansData.sort((a, b) => {
      //    return a.sequence_no - b.sequence_no;
      // });

      return ansData;
   }

   function setDefaultAnswers(type, options_length) {
      let retVal = [];
      var multiple = type.includes("multiple");
      var matching = type.includes('matching_type');

      if (multiple) {
         for (var i = 0; i < options_length; i++) {
            retVal.push("0");
         }
      }

      if (matching) {
         for (var i = 0; i < options_length; i++) {
            retVal.push("");
         }
      }

      return retVal;
   }

   function handleAnswerRequest(data, idx) {
      var ad = [...answerSheetData];
      var sad = [...answerData];

      var multiple = ad[idx].type.includes("multiple");
      var matching = ad[idx].type.includes("matching");
      // var section = ad[idx].type.includes("section");

      // if (section) {
      //    // sad[idx].correct = ad[idx].correct;
      //    sad[idx].correct = []; sad[idx].correct.push.apply(sad[idx].correct, ad[idx].correct);
      // }

      ad[idx].answer = [];
      sad[idx].answer = [];

      if (multiple || matching) {
         ad[idx].answer.push.apply(ad[idx].answer, data);
         sad[idx].answer.push.apply(sad[idx].answer, data);
      } else {
         ad[idx].answer[0] = data;
         sad[idx].answer[0] = data;
      }

      setAnswerSheetData(ad);
      setAnswerData(sad);

      //-- Save answer to local storage after every answer filled
      localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_myanswers");
      localStorage.setItem(user_id + "_" + assessmentInfo.Id + "_myanswers", JSON.stringify(sad));

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
      // message.info(JSON.stringify(answerData));
   }

   const handleNavigate = () => {
      // navigate(`/studentmasteryassessment/preview?assign_id=${assignInfo.id}&mastery_id=${masteryrec.id}&origin=${origin}&auto_proceed=${true}&qt=2`);
      navigate(`/studentmasteryassessment/preview?assign_id=${assignInfo.id}&mastery_id=${masteryrec.id}&origin=${origin}&auto_proceed=${true}&qt=2`);
   }

   const handleSubmit = () => {
      setIsSubmitted(true);
      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      let timestampz = new Date().getTime();

      var question_seq = JSON.parse(localStorage.getItem(user_id + "_" + assessmentInfo.Id + "_my_sequence"));
      var answers = answerData.length > 0 ? answerData : JSON.parse(localStorage.getItem(user_id + "_" + assessmentInfo.Id + "_myanswers"));

      //-- Sort the answers to original sequence before saving
      if (answers[0].idx !== undefined) {
         answers.sort((a, b) => {
            return a.idx - b.idx;
         });
      }

      var ansInfo = {
         Id: "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + user_id,
         Mastery_assessment_id: assessmentInfo.Id,
         Mastery_assignment_id: assignInfo.id,
         Student_id: user_id,
         User_agent: platform.ua,
         Browser: platform.name,
         Browser_version: platform.version,
         OS_platform: platform.os.family,
         Device: platform.product,
         Assessment_type: assessmentType,
         Answer: JSON.stringify(answers),
         Submit_status: 1,
         Score: 0,
         Essay_score: 0,
         Expiration: null,
         Status: -1,
         Question_sequence: question_seq !== null ? question_seq.join(',') : null,
      }

      const updateAnsUrl = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/updateanswer?assessment_type=${assessmentType}&cache=${uuid()}`;
      axios.post(updateAnsUrl, ansInfo).then(response => {
         if (response.data) {
            const data = response.data;

            localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_timestarted");
            localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_myanswers");
            localStorage.removeItem(user_id + "_" + assessmentInfo.Id + "_my_sequence");

            setIsSubmitted(false);

            handleNavigate();
         }
      }, (error) => {
         setIsSubmitted(false);

         Modal.error({
            title: 'Mastery Assessment',
            content: 'Submission of assessment answers failed.',
            centered: true
         });
      });

   }

   const contentStyle = {
      marginBottom: 0,
      // padding: 15,
      height: 'calc(100vh - 110px)',
      color: '#000',
      // background: '#9CDFF0',
      // textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: 'auto',
      // borderRadius: '7px'
   };

   const handleAfterChange = (current) => {
      setQuestionIndex(current + 1);
   }

   function getInstruction(formative_type) {
      var retVal = '';

      switch (formative_type) {
         case 'formative_1':
            retVal = masteryrec.formative_1_instruction;
            break;
         case 'formative_2':
            retVal = masteryrec.formative_2_instruction;
            break;
         case 'formative_3':
            retVal = masteryrec.formative_3_instruction;
            break;
         case 'summative':
            retVal = masteryrec.summative_instruction;
            break;
         default:
            retVal = '';
      }

      return retVal;
   }

   const handleGoTo = (value) => {
      const re = /^[0-9]+$/;

      if (re.test(value) || value === "") {
         setQuestionIndex(value);
      }
   }

   return (
      <>
         {
            isSorted &&
            <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
               <div style={{ width: `100%` }}>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        <Breadcrumb separator=">">
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: "1" } })}>Mastery Assessment</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?origin=${origin}&qt=${assignInfo.mode}`)}>Start</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>{Utils.GetTypeLabel(assessmentType)}</Breadcrumb.Item>
                        </Breadcrumb>

                        <div style={{ width: '100%', textAlign: 'center' }}>
                           {/* <h5 style={{ textAlign: "center", marginBottom: '0px' }}>{assessmentInfo.Title + ' - ' + Utils.GetTypeLabel(assessmentType)} {instruction !== '' && instruction !== null ? <Button className='button-shadow' type='primary' shape='round' onClick={() => setOpenInstruction(true)}><EyeOutlined /> Instruction</Button> : <></>}</h5> */}
                           {instruction !== '' && instruction !== null ? <Button className='button-shadow' type='primary' shape='round' onClick={() => setOpenInstruction(true)}><EyeOutlined /> Instruction</Button> : <></>}
                        </div>

                     </div>
                     <div className='answer-sheet-content2' style={{ height: 'calc(100vh - 125px)', overflowY: 'auto' }}>
                        <Carousel
                           dotPosition='bottom'
                           ref={carouselRef}
                           effect='scrollx'
                           dots={false}
                           draggable={true}
                           style={{ height: 'calc(100vh - 255px)' }}
                           afterChange={handleAfterChange}
                           infinite={false}
                        >
                           {
                              answerSheetData.map(({ id, type, points, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                 if (type === "section") {
                                    qcardIdx = 1;
                                    idxCol.push(sectionIdx);
                                    sectionIdx++;
                                 } else {
                                    idxCol.push(qcardIdx);
                                    qcardIdx++;
                                 }

                                 return (
                                    <span style={contentStyle}>
                                       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                          <BraineeQuestionCard
                                             key={id}
                                             index={idxCol[idx]}
                                             id={idx}
                                             type={type}
                                             points={points}
                                             correct={answerData[idx].answer}
                                             option_labels={option_labels}
                                             question={question}
                                             image_url={image_url}
                                             question_type={'2'}
                                             mode={'answer'}
                                             answerCallback={handleAnswerRequest}
                                             card_bg={location.state.card_bg}
                                             template={template}
                                             question_image={question_image}
                                             question_audio={question_audio}
                                             question_video={question_video}
                                             subject_name={location.state.subject}
                                             assessment_type={'mastery'}
                                          />
                                       </div>
                                    </span>
                                 );
                              })
                           }
                        </Carousel>
                     </div>
                     {/* <StickyBox bottom={true} style={{ zIndex: 1000, paddingBottom: "3px", width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
                     {/* <div className='btn-container' style={{ zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
                     <div style={{ position: 'sticky', bottom: '5px', width: '100%', zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Row style={{ width: '100%', maxWidth: '400px' }} gutter={12}>
                           <Col span={6}>
                              <Button className='button-shadow' type='primary' block shape='round' onClick={() => { carouselRef.current.prev(); }} disabled={questionIndex <= 1 || isSubmitted ? true : false}>
                                 {'< prev'}
                              </Button>
                           </Col>
                           <Col span={12}>
                              {
                                 questionIndex === answerSheetData.length
                                    ?
                                    <Popconfirm
                                       title="Click 'Yes', if you have already completed your answers, 'No', if you want to continue answering."
                                       onConfirm={() => handleSubmit()}
                                       // onCancel={cancel}
                                       okText="Yes"
                                       cancelText="No"
                                       icon={<QuestionCircleOutlined />}
                                    >
                                       <Button className='button-shadow' type='primary' block shape='round' disabled={isSubmitted}>
                                          <Text ellipsis style={{ color: "#fff" }}>Submit Answer</Text>
                                       </Button>
                                    </Popconfirm>
                                    :
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                       <div onBlur={() => carouselRef.current.goTo(questionIndex - 1)}>
                                          <Input
                                             size='default'
                                             style={{ width: '50px', textAlign: "center", borderRadius: "7px" }}
                                             value={questionIndex}
                                             onChange={(e) => handleGoTo(e.target.value)}
                                             onPressEnter={() => carouselRef.current.goTo(questionIndex - 1)}
                                          />
                                       </div>
                                       &nbsp;
                                       <Text
                                          ellipsis
                                          style={{ color: "#70D0D4", fontSize: '18px', cursor: 'pointer' }}
                                       >
                                          {' of ' + answerSheetData.length}
                                       </Text>
                                    </div>
                              }
                           </Col>
                           <Col span={6}>
                              <Button className='button-shadow' type='primary' block shape='round' onClick={() => { carouselRef.current.next(); }} disabled={questionIndex === answerSheetData.length ? true : false}>
                                 {'next >'}
                              </Button>
                           </Col>
                        </Row>
                     </div>
                     {/* </StickyBox> */}
                  </div>
               </div>
            </div>
         }

         <Modal
            key="Modal-Instruction"
            title={<>Instruction</>}
            destroyOnClose={true}
            centered
            open={openInstruction}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ shape: 'round', type: 'primary', className: "button-shadow" }}
            onCancel={() => setOpenInstruction(false)}
            onOk={() => setOpenInstruction(false)}
            okText="Ok"
            cancelText="Close"
            width={"55%"}
            style={{ minWidth: "350px", maxWidth: "95%" }}
         // footer={false}
         >
            <div
               dangerouslySetInnerHTML={{ __html: getInstruction(assessmentType) }}
               style={{ textOverflow: 'ellipsis', overflow: 'auto', backgroundColor: '#FFF', maxHeight: '300px', padding: '10px', borderRadius: '7px' }}
            />
         </Modal>
      </>
   )
}

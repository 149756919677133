/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { Empty, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ProgressBar from "@ramonak/react-progress-bar";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";

export function MTG1LanguageReport(props) {
   const [reportData, setReportData] = useState(null);

   const [interaction, setInteraction] = useState(null);
   const [developing, setDeveloping] = useState(null);
   const [appreciating, setAppreciating] = useState(null);
   const [interacting, setInteracting] = useState(null);
   const [creating, setCreating] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      ChartJS.register(
         CategoryScale,
         LinearScale,
         BarElement,
         Title,
         Tooltip,
         Legend
      );

      getReport(props.subject_id, props.assignment_id, props.student_id, props.template)
   }, []);

   const getReport = (subject, assignment_id, student_id, template) => {
      setDataLoading(true);
      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getdiagnosticreport?s=${subject}&ai=${assignment_id}&si=${student_id}&t=${template}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data[0];

            if (data !== undefined) {
               setInteraction(data.s1 + data.s2 + data.s3 + data.s4 + data.s5 + data.s6 + data.s7 + data.s8 + data.s9);
               setDeveloping(data.s10);
               setAppreciating(data.s11 + data.s12 + data.s13 + data.s14 + data.s15 + data.s16 + data.s17 + data.s18);
               setInteracting(data.s19 + data.s20 + data.s21 + data.s22 + data.s23 + data.s24 + data.s25 + data.s26);
               setCreating(data.s27 + data.s28 + data.s29 + data.s30 + data.s31 + data.s32 + data.s33 + data.s34 + data.s35);

               setReportData(data);
            }

            setDataLoading(false);
         }
      }, (error) => {
         setDataLoading(false);
         console.log(error.message);
      });
      // }
   }

   const renderPartLabel = (title) => {
      return (
         <tr>
            <td style={{ width: '12.6pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
            </td>
            <td style={{ width: '382.3pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>{title}</strong></p>
            </td>
            <td style={{ width: '31.7pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
            </td>
         </tr>
      );
   }

   const renderCompetencyRow = (idx, label, answer, pre_percent, post_percent) => {
      return (
         <tr>
            <td style={{ width: '12.6pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{idx}</strong></p>
            </td>
            <td style={{ width: '382.3pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>{label}</p>
            </td>
            <td style={{ width: '31.7pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{answer === 1 || answer === '1' ? <CheckOutlined style={{ fontSize: '22px', color: '#52c41a' }} /> : <CloseOutlined style={{ fontSize: '22px', color: '#eb2f96' }} />}</p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {
                     pre_percent !== 0 &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        // labelColor={pre_percent <= 40 ? "#000000" : "#FFFFFF"}
                        labelColor={"#FFFFFF"}
                        // labelAlignment={pre_percent <= 40 ? "outside" : "center"}
                        labelAlignment={"center"}
                        // customLabel={pre_percent.toFixed(1) + "%"}
                        completed={pre_percent}
                        labelSize="12px"
                        animateOnRender
                     />
                  }
               </p>
            </td>
            <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {/* {
                     pre_percent !== 0 &&
                     <ProgressBar
                        borderRadius="3px"
                        bgColor="#4F81BD"
                        labelAlignment="center"
                        completed={pre_percent}
                        labelSize="12px"
                        animateOnRender
                     />
                  } */}
               </p>
            </td>
         </tr>
      );
   }

   const renderSummaryRow = (label, raw_score, item_count, s1, s2) => {
      return (
         <tr>
            <td style={{ width: '197.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>{label}</strong></p>
            </td>
            <td style={{ width: '79.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {raw_score}
               </p>
            </td>
            <td style={{ width: '61.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {(((raw_score) / item_count) * 100).toFixed(2)}%
               </p>
            </td>
            <td style={{ width: '59.9pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {((raw_score - s1) / s2).toFixed(2)}
               </p>
            </td>
            <td style={{ width: '99.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
               <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                  {((raw_score - s1) / s2) > 1 ? 'Competent' : (((raw_score - s1) / s2) >= -0.51 ? 'Basic Level' : (((raw_score - s1) / s2) > -10 ? 'Beginner' : ''))}
               </p>
            </td>
         </tr>
      );
   }

   const graph_options = {
      responsive: true,
      plugins: {
         legend: {
            position: 'top',
         },
         title: {
            display: true,
            text: 'Percent Correct for the Content Standards',
         },
      },
      scales: {
         y:
         {
            min: 0,
            max: 100,
            stepSize: 1,
         },
         x:
            {},
      }
   };
   const labels = ["Language for interaction with others", "Language for developing and expressing ideas", "Appreciating Language", "Interacting with text", "Creating Text"];
   const graph_data = {
      labels: labels,
      datasets: [
         {
            label: "Percentage",
            backgroundColor: "#4F81BD",
            borderColor: "#4F81BD",
            data: [
               !isNaN((interaction / 9) * 100) ? ((interaction / 9) * 100).toFixed(2) : 0,
               !isNaN((developing / 1) * 100) ? ((developing / 1) * 100).toFixed(2) : 0,
               !isNaN((appreciating / 8) * 100) ? ((appreciating / 8) * 100).toFixed(2) : 0,
               !isNaN((interacting / 8) * 100) ? ((interacting / 8) * 100).toFixed(2) : 0,
               !isNaN((creating / 9) * 100) ? ((creating / 9) * 100).toFixed(2) : 0
            ],
         },
      ],
   };

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               reportData !== null
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='individual-report'>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', lineHeight: '115%', fontSize: '14pt' }}><strong>Learners Profile on Science Kindergarten</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', lineHeight: '115%', paddingBottom: '1pt', fontSize: '12pt' }}>&nbsp;</p>
                        <table cellSpacing={0} cellPadding={0} style={{ width: '100%', border: '0.75pt solid #cccccc', }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '50.84%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Name:&nbsp;</strong>{reportData.student_name}</p>
                                 </td>
                                 <td style={{ width: '28.64%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Grade Level:&nbsp;</strong>Kindergarten</p>
                                 </td>
                                 <td style={{ width: '20.52%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Age:</strong></p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '50.84%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>School:&nbsp;</strong>{reportData.school_name}</p>
                                 </td>
                                 <td style={{ width: '28.64%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Section:&nbsp;</strong>{reportData.section}</p>
                                 </td>
                                 <td style={{ width: '20.52%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '50.84%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Date:&nbsp;</strong>{reportData.diagnostic_date}</p>
                                 </td>
                                 <td style={{ width: '28.64%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                                 </td>
                                 <td style={{ width: '20.52%', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>

                        <div style={{ clear: 'both' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', paddingTop: '1pt', fontSize: '10pt' }}>&nbsp;</p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt', textAlign: 'justify' }}>The K to 12 Learning Assessment Package determines how much the learners have benefited from the instruction of the grade level and subject in this result. The assessment determines a set of standard competencies based on the National Curriculum. These learning competencies are classified according to the content standards as presented in the results. When the learning competency is already taken up in class, the purpose of the assessment is to determine the learners’ achievement or what they have learned from instruction. If the learning competency tested is not yet taken, the results serve as diagnostic or determine what students’ know and does not know so that necessary adjustment in the teaching and learning process can be prepared.</p>
                        </div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '10pt', textAlign: 'justify' }}><strong>Competency Score.</strong>&nbsp; Each item in the test is aligned to a particular learning competency. If the mark is “1”, the learner is able to meet the competency. If the mark is “0”, the learner is not able to meet that competency. The percentage beside the competency score is the percent of the entire level who is able to meet the learning competency.</p>
                        <table cellSpacing={0} cellPadding={0} style={{ border: '0.75pt solid #cccccc', borderCollapse: 'collapse', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '58.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>0% - 40%</p>
                                 </td>
                                 <td style={{ width: '446.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>Very few learners are able to meet the learning competency. The learners need to review the lessons again.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '58.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>41%- 60%</p>
                                 </td>
                                 <td style={{ width: '446.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>About half of the learners are able to meet the learning competency. The learners need to review the lessons again.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '58.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>61% - 80%</p>
                                 </td>
                                 <td style={{ width: '446.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>Majority of the learners are able to meet the learning competency. The remaining students can work on supplementary learning resources to study.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '58.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>81% - 100%</p>
                                 </td>
                                 <td style={{ width: '446.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>A large number of learners are able to meet the learning competency. The remaining learners can be provided with extra support.</p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '12pt' }}><strong>Competency Score</strong></p>
                        <div style={{ textAlign: 'center' }}>
                           <table cellSpacing={0} cellPadding={0} style={{ marginRight: 'auto', marginLeft: 'auto', border: '0.75pt solid #cccccc', borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Learning Competency</span></strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Score</span></strong></p>
                                    </td>
                                    <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Pretest Percent Correct for the Level</span></strong></p>
                                    </td>
                                    <td style={{ width: '81pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Posttest Percent Correct for the Level</span></strong></p>
                                    </td>
                                 </tr>
                                 {renderPartLabel("Part 1: Language for interaction with others")}
                                 {renderCompetencyRow("1", "Use common and socially acceptable expressions: Use simple and appropriate personal greetings.", reportData.s1, reportData.percent_correct_s1, reportData.percent_correct_s1)}
                                 {renderCompetencyRow("2", "Use common and socially acceptable expressions: Use familiar terms of address.", reportData.s2, reportData.percent_correct_s2, reportData.percent_correct_s2)}
                                 {renderCompetencyRow("3", "Use common and socially acceptable expressions: Greet and respond appropriately to greetings", reportData.s3, reportData.percent_correct_s3, reportData.percent_correct_s3)}
                                 {renderCompetencyRow("4", "Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Make requests", reportData.s4, reportData.percent_correct_s4, reportData.percent_correct_s4)}
                                 {renderCompetencyRow("5", "Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Give or offer information", reportData.s5, reportData.percent_correct_s5, reportData.percent_correct_s5)}
                                 {renderCompetencyRow("6", "Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Communicate needs", reportData.s6, reportData.percent_correct_s6, reportData.percent_correct_s6)}
                                 {renderCompetencyRow("7", "Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Clarify information", reportData.s7, reportData.percent_correct_s7, reportData.percent_correct_s7)}
                                 {renderCompetencyRow("8", "Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Seek help", reportData.s8, reportData.percent_correct_s8, reportData.percent_correct_s8)}
                                 {renderCompetencyRow("9", "Interact purposely and participate in conversations and discussions in pairs, in groups, or in whole-class discussion: Take part in or take turns in conversation or discussion", reportData.s9, reportData.percent_correct_s9, reportData.percent_correct_s9)}
                                 {renderPartLabel("Part 2: Language for developing and expressing ideas")}
                                 {renderCompetencyRow("10", "Express ideas using a variety of symbols (e.g., drawings, emojis, scribbles): (a) oneself and family (b) content-specific topics", reportData.s10, reportData.percent_correct_s10, reportData.percent_correct_s10)}
                                 {renderPartLabel("Part 3: Appreciating Language")}
                                 {renderCompetencyRow("11", "Use words to represent ideas and events related to oneself and family: words that represent people, animals, objects, locations (naming words)", reportData.s11, reportData.percent_correct_s11, reportData.percent_correct_s11)}
                                 {renderCompetencyRow("12", "Use words to represent ideas and events related to oneself and family: words that represent activities and situations (action words)", reportData.s12, reportData.percent_correct_s12, reportData.percent_correct_s12)}
                                 {renderCompetencyRow("13", "Use words to represent ideas and events related to oneself and family: words that represent qualities or attributes (describing words)", reportData.s13, reportData.percent_correct_s13, reportData.percent_correct_s13)}
                                 {renderCompetencyRow("14", "Use language to express connections between ideas: Express compare and contrast", reportData.s14, reportData.percent_correct_s14, reportData.percent_correct_s14)}
                                 {renderCompetencyRow("15", "Use language to express connections between ideas: Express cause and effect", reportData.s15, reportData.percent_correct_s15, reportData.percent_correct_s15)}
                                 {renderCompetencyRow("16", "Use language to express connections between ideas: Use time words to relate ideas", reportData.s16, reportData.percent_correct_s16, reportData.percent_correct_s16)}
                                 {renderCompetencyRow("17", "Recognize the difference between statements, questions, commands and exclamations.", reportData.s17, reportData.percent_correct_s17, reportData.percent_correct_s17)}
                                 {renderCompetencyRow("18", "Respond to change of tones and cues through facial expressions, gestures and actions", reportData.s18, reportData.percent_correct_s18, reportData.percent_correct_s18)}
                                 {renderPartLabel("Part 4: Interacting with text")}
                                 {renderCompetencyRow("19", "Recognize icons and symbols in various texts found in familiar contexts (e.g., printed and digital texts, books, magazines, environmental print).", reportData.s19, reportData.percent_correct_s19, reportData.percent_correct_s19)}
                                 {renderCompetencyRow("20", "Engage with or respond to a short spoken texts: Identify a variety of purposes for viewing and listening to texts", reportData.s20, reportData.percent_correct_s20, reportData.percent_correct_s20)}
                                 {renderCompetencyRow("21", "Engage with or respond to a short spoken texts: Discuss what is interesting or entertaining in a text", reportData.s21, reportData.percent_correct_s21, reportData.percent_correct_s21)}
                                 {renderCompetencyRow("22", "Engage with or respond to a short spoken texts: Express personal preferences", reportData.s22, reportData.percent_correct_s22, reportData.percent_correct_s22)}
                                 {renderCompetencyRow("23", "Give reason/s for choosing books/texts for enjoyment and interest.", reportData.s23, reportData.percent_correct_s23, reportData.percent_correct_s23)}
                                 {renderCompetencyRow("24", "Record and report ideas and events using some learnt vocabulary: Note and describe main points", reportData.s24, reportData.percent_correct_s24, reportData.percent_correct_s24)}
                                 {renderCompetencyRow("25", "Record and report ideas and events using some learnt vocabulary: Sequence up to three (3) key events", reportData.s25, reportData.percent_correct_s25, reportData.percent_correct_s25)}
                                 {renderCompetencyRow("26", "Record and report ideas and events using some learnt vocabulary: Relate ideas or events to one’s experience", reportData.s26, reportData.percent_correct_s26, reportData.percent_correct_s26)}
                                 {renderPartLabel("Part 5: Creating Text")}
                                 {renderCompetencyRow("27", "Use own words in retelling", reportData.s27, reportData.percent_correct_s27, reportData.percent_correct_s27)}
                                 {renderCompetencyRow("28", "Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Note and describe main points (e.g., main characters and events)", reportData.s28, reportData.percent_correct_s28, reportData.percent_correct_s28)}
                                 {renderCompetencyRow("29", "Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Note and describe main points (e.g., main characters and events)", reportData.s29, reportData.percent_correct_s29, reportData.percent_correct_s29)}
                                 {renderCompetencyRow("30", "Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Note and describe main points (e.g., main characters and events)", reportData.s30, reportData.percent_correct_s30, reportData.percent_correct_s30)}
                                 {renderCompetencyRow("31", "Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Sequence up to three (3) key events", reportData.s31, reportData.percent_correct_s31, reportData.percent_correct_s31)}
                                 {renderCompetencyRow("32", "Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Infer the character’s feelings and traits", reportData.s32, reportData.percent_correct_s32, reportData.percent_correct_s32)}
                                 {renderCompetencyRow("33", "Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Note and describe main points (e.g., main characters and events)", reportData.s33, reportData.percent_correct_s33, reportData.percent_correct_s33)}
                                 {renderCompetencyRow("34", "Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Predict possible endings", reportData.s34, reportData.percent_correct_s34, reportData.percent_correct_s34)}
                                 {renderCompetencyRow("35", "Draw and discuss information or ideas from a range of text (e.g., stories, images, digital texts): Relate ideas or events to one’s experience", reportData.s35, reportData.percent_correct_s35, reportData.percent_correct_s35)}

                              </tbody>
                           </table>
                        </div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><a name="_Hlk103365502"><strong>Summary of Results.</strong>&nbsp; The result for the content standards is expressed in terms of the raw score, percent correct, standard score, and proficiency level.&nbsp;</a></p>
                        <ul type="disc" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Raw Score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The raw score is the total number of correct answers for the content standard.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Percent Correct.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The percent correct is the raw score converted into percentage. The percent correct provides you with an easier way to determine how many correct answers you are able to obtain for each content standard. If the percent correct is close to 100%, the more correct answers you are able to attain.</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Standard score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The scores are converted into a standard score to allow comparison of scores across content standards and across levels. A standard score near 0.0 means that the learner’s standing in the content standard is within the majority of the test takers. A standard score of more than 1.00 mean that the attainment of the competencies is high because there are several correct answers.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt', textAlign: 'justify' }}><strong><span style={{ fontFamily: 'Calibri' }}>Proficiency Level.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The proficiency level describes your present level of attainment on content standard. The levels of proficiency start from beginner to basic to competent. The beginner level means few correct answers attained in the content standard. The basic level means that the score is within the majority of the test takers. Competent means that several correct answers are attained.</span><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span></li>
                        </ul>
                        {
                           graph_data !== null &&
                           <div style={{ marginTop: "20px", paddingLeft: '120px', paddingRight: "120px" }}>
                              <Bar options={graph_options} data={graph_data} />
                           </div>
                        }


                        <div style={{ pageBreakBefore: 'always' }}></div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '12pt' }}><strong>Summary of Results</strong></p>
                        <table cellSpacing={0} cellPadding={0} style={{ border: '0.75pt solid #cccccc', borderCollapse: 'collapse', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '197.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Areas</span></strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Raw Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent Correct</span></strong></p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Standard Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong></p>
                                 </td>
                              </tr>
                              {renderSummaryRow("Language for interaction with others", interaction, 9, 6.57, 2.11)}
                              {renderSummaryRow("Language for developing and expressing ideas", developing, 1, 0.5, 0.49)}
                              {renderSummaryRow("Appreciating Language", appreciating, 8, 5.12, 2.19)}
                              {renderSummaryRow("Interacting with text", interacting, 8, 4.6, 1.84)}
                              {renderSummaryRow("Creating and Composing Text", creating, 9, 4.83, 2.24)}
                              {renderSummaryRow("Total", interaction + developing + appreciating + interacting + creating, 35, 26.93, 2.24)}
                           </tbody>
                        </table>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>Interpretation&nbsp;</strong></p>
                        <table cellSpacing={0} cellPadding={0} style={{ border: '0.75pt solid #cccccc', borderCollapse: 'collapse', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '107.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong></p>
                                 </td>
                                 <td style={{ width: '133.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Range of Standard Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '278.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#4F81BD' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Interpretation</span></strong></p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '107.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Beginner</strong></p>
                                 </td>
                                 <td style={{ width: '133.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>Less than -0.5</p>
                                 </td>
                                 <td style={{ width: '278.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>There are several competencies that the student did not master and may need thorough instructional support.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '107.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Basic Level</strong></p>
                                 </td>
                                 <td style={{ width: '133.2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>-0.51 to 1.0</p>
                                 </td>
                                 <td style={{ width: '278.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>The student has learned sufficient competencies to move to the succeeding level of learning. Needs instructional support to move to the next level.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '107.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Competent Level</strong></p>
                                 </td>
                                 <td style={{ width: '133.2pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>1.00 and above</p>
                                 </td>
                                 <td style={{ width: '278.1pt', borderStyle: 'solid', borderWidth: '0.75pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>The student has mastered the required competencies and needs little instruction to learn succeeding lessons.</p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}><strong>Name:&nbsp;</strong>{reportData.student_name}</p>
                     </div>
                  </div>
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }
      </>
   );
}
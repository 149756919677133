/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Input, Row, Col,
   Modal, Typography, Image, Dropdown, Menu
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
// import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../../misc/Utils';
// import Enums from '../../../misc/Enums';
import {
   EnglishKReport, English1Report, English2Report, English3Report, English4Report, English5Report, English6Report, English7Report, English8Report, English9Report, English10Report,
   ScienceKReport, Science1Report, Science2Report, Science3Report, Science4Report, Science5Report, Science6Report, Science7Report, Science8Report, Science9Report, Science10Report,
   MathKReport, Math1Report, Math2Report, Math3Report, Math4Report, Math5Report, Math6Report, Math7Report, Math8Report, Math9Report, Math10Report,
   Grade1SummaryReport, Grade2SummaryReport, Grade3SummaryReport, Grade4SummaryReport, Grade5SummaryReport, Grade6SummaryReport, Grade7SummaryReport, Grade8SummaryReport,
   Grade9SummaryReport, Grade10SummaryReport, KinderSummaryReport,
   GMG11Report, Physics1G12Report, Biology1G12Report, FABM2G12Report, WRBSG12Report, OCG11Report, PPGG12Report, BFG12Report, BESRG12Report, RAWG11Report, CWG12Report, TNCTG12Report,
   GP2G12Report, GB2G12Report, SPG11Report, AEG12Report,

   MTLanguageKReport, MTScienceKReport, MTMathKReport, MTKLanguageSummaryReport, MTKScienceSummaryReport, MTKMathSummaryReport,
   MTG1LanguageReport, MTG1MathReport, MTG1RALReport, MTG1LanguageSummaryReport, MTG1MathSummaryReport, MTG1RALSummaryReport,
   MTG4EnglishReport, MTG4MathReport, MTG4ScienceReport, MTG4EnglishSummaryReport, MTG4MathSummaryReport, MTG4ScienceSummaryReport,
   MTG7EnglishReport, MTG7MathReport, MTG7ScienceReport, MTG7EnglishSummaryReport, MTG7MathSummaryReport, MTG7ScienceSummaryReport,

   BFG12SummaryReport, PPGG12SummaryReport, GP1G12SummaryReport, GB1G12SummaryReport, FABM2G12SummaryReport, WRBSG12SummaryReport, OCG11SummaryReport, GMG11SummaryReport, RAWG11SummaryReport,
   CWG12SummaryReport, TNCTG12SummaryReport, BESRG12SummaryReport, GP2G12SummaryReport, GB2G12SummaryReport, SPG11SummaryReport, AEG12SummaryReport,
} from '.';

import html2pdf from 'html2pdf.js';

const { Text } = Typography;

export function BStudentReports(props) {
   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [listLoading, setListLoading] = useState(false);
   const [studentList, setStudentList] = useState([]);
   const [subject, setSubject] = useState(null);
   const [showReport, setShowReport] = useState(false);
   const [studentID, setStudentID] = useState(null);
   const [menuItems, setMenuItems] = useState([]);
   const [showSummaryReport, setShowSummaryReport] = useState(false);
   const [sectionID, setSectionID] = useState(null);
   const [sectionDesc, setSectionDesc] = useState(null);
   const [subjectID, setSubjectID] = useState(null);

   const [downloadStatus, setDownloadStatus] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      getStudentsWithReports(props.id);
   }, [props.id]);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'student_id',
         key: 'student_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'section',
         key: 'section',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'level',
         key: 'level',
         hidden: true,
      },
      {
         title: 'Student Name',
         key: 'student_name',
         ...getColumnSearchProps('student_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.student_name}>
               <Text>
                  {
                     payload.student_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                  }
               </Text>
            </Tooltip>
         ),
         width: props.subject === null ? '30%' : '45% '
      },
      {
         title: 'Level',
         key: 'level_desc',
         ...getColumnSearchProps('level_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.level_desc}>
               <Text>
                  {
                     payload.level_desc
                  }
               </Text>
            </Tooltip>
         ),
         width: '15%'
      },
      {
         title: 'Section',
         key: 'section_desc',
         ...getColumnSearchProps('section_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.section_desc}>
               <Text>
                  {
                     payload.section_desc
                  }
               </Text>
            </Tooltip>
         ),
         width: '25%'
      },
      {
         title: <div style={{ textAlign: 'center' }}>Report</div>,
         render: payload => (
            payload.report_id !== null &&
            <>
               <div style={{ textAlign: 'center' }}>
                  <Tooltip placement='left' title={'Show Report on ' + props.subject.toUpperCase()}>
                     <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowReport(payload.student_id, props.subject, props.subject_id)} />
                  </Tooltip>
               </div>
            </>

         ),
         width: '15%',
         hidden: props.subject_id !== null && props.subject_id !== 'ems' ? false : true,
      },
      {
         title: <div style={{ textAlign: 'center' }}>English</div>,
         render: payload => (
            payload.report_id !== null &&
            <>
               <div style={{ textAlign: 'center' }}>
                  <Tooltip placement='left' title='Show Report on English'>
                     <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowReport(payload.student_id, 'English', 'english')} />
                  </Tooltip>
               </div>
            </>

         ),
         width: '10%',
         hidden: props.subject_id === null || props.subject_id === 'ems' ? false : true,
      },
      {
         title: <div style={{ textAlign: 'center' }}>Math</div>,
         render: payload => (
            payload.report_id !== null &&
            <>
               <div style={{ textAlign: 'center' }}>
                  <Tooltip placement='left' title='Show Report on Math'>
                     <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowReport(payload.student_id, 'Math', 'math')} />
                  </Tooltip>
               </div>
            </>
         ),
         width: '10%',
         hidden: props.subject_id === null || props.subject_id === 'ems' ? false : true,
      },
      {
         title: <div style={{ textAlign: 'center' }}>Science</div>,
         render: payload => (
            payload.report_id !== null &&
            <>
               <div style={{ textAlign: 'center' }}>
                  <Tooltip placement='left' title='Show Report on Science'>
                     <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowReport(payload.student_id, 'Science', 'science')} />
                  </Tooltip>
               </div>
            </>
         ),
         width: '10%',
         hidden: props.subject_id === null || props.subject_id === 'ems' ? false : true,
      },
   ].filter(item => !item.hidden);

   const getStudentsWithReports = (id) => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/getstudentswithreport?id=${id}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            var tmpMenuItems = [];
            var tmpMenuItem = {};
            var curSection = "";

            //-- Add item All Section
            tmpMenuItem = {
               label: 'All Sections',
               key: '0',
               icon: <AntDIcons.SolutionOutlined />,
            }

            tmpMenuItems.push(tmpMenuItem);

            data.forEach(element => {
               if (curSection !== element.section_desc) {
                  tmpMenuItem = {
                     label: element.section_desc,
                     key: element.section.toString(),
                     icon: <AntDIcons.SolutionOutlined />,
                  }

                  tmpMenuItems.push(tmpMenuItem);
                  curSection = element.section_desc;
               }
            });

            setMenuItems(tmpMenuItems);
            setListLoading(false);
            setStudentList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const handleShowReport = (student_id, subject, subject_id) => {
      setSubject(subject);
      setSubjectID(subject_id);
      setStudentID(student_id);
      setShowReport(true);
   }

   const displayReport = (template, assign_id) => {
      if (subjectID !== null && showReport) {
         switch (template) {
            case 'PreKinder':
            case 'Kindergarten':
            case 'Kindergarten 1':
            case 'Kindergarten 2':
            case 'Casa':
            case 'Advance Casa':
            case 'Senior Casa':
               switch (subjectID) {
                  case 'english':
                     return (
                        <EnglishKReport
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <MathKReport
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <ScienceKReport
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtlang':
                     return (
                        <MTLanguageKReport
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTMathKReport
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtsci':
                     return (
                        <MTScienceKReport
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 1':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English1Report
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math1Report
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science1Report
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtlang':
                     return (
                        <MTG1LanguageReport
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTG1MathReport
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtral':
                     return (
                        <MTG1RALReport
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 2':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English2Report
                           subject={props.subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math2Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science2Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 3':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English3Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math3Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science3Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 4':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English4Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math4Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science4Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mteng':
                     return (
                        <MTG4EnglishReport
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTG4MathReport
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtsci':
                     return (
                        <MTG4ScienceReport
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 5':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English5Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math5Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science5Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 6':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English6Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math6Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science6Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 7':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English7Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math7Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science7Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mteng':
                     return (
                        <MTG7EnglishReport
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTG7MathReport
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'mtsci':
                     return (
                        <MTG7ScienceReport
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 8':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English8Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math8Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science8Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 9':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English9Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math9Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science9Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 10':
               switch (subjectID) {
                  case 'english':
                     return (
                        <English10Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'math':
                     return (
                        <Math10Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'science':
                     return (
                        <Science10Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;

            case 'Grade 11':
               switch (subjectID) {
                  case 'oc':
                     return (
                        <OCG11Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'raw':
                     return (
                        <RAWG11Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'gm':
                     return (
                        <GMG11Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'stats':
                     return (
                        <SPG11Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
            case 'Grade 12':
               switch (subjectID) {
                  case 'besr':
                     return (
                        <BESRG12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'gp1':
                     return (
                        <Physics1G12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'gb1':
                     return (
                        <Biology1G12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'gp2':
                     return (
                        <GP2G12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'gb2':
                     return (
                        <GB2G12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'fabm2':
                     return (
                        <FABM2G12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'wrbs':
                     return (
                        <WRBSG12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'ppg':
                     return (
                        <PPGG12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'bf':
                     return (
                        <BFG12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'cw':
                     return (
                        <CWG12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'tnct':
                     return (
                        <TNCTG12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
                  case 'ae':
                     return (
                        <AEG12Report
                           subject={subject}
                           assignment_id={assign_id}
                           student_id={studentID}
                           template={template}
                           subject_id={subjectID}
                        />
                     );
               }
               break;
         }
      }
   }

   const displaySummaryReport = () => {
      if (showSummaryReport) {
         switch (props.template) {
            case 'PreKinder':
            case 'Kindergarten':
            case 'Kindergarten 1':
            case 'Kindergarten 2':
            case 'Casa':
            case 'Advance Casa':
            case 'Senior Casa':
               switch (props.subject_id) {
                  case 'mtlang':
                     return (
                        <MTKLanguageSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'mtsci':
                     return (
                        <MTKScienceSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTKMathSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'ems':
                     return (
                        <KinderSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  default:
                     return (
                        <KinderSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
               }
            case 'Grade 1':
               switch (props.subject_id) {
                  case 'mtlang':
                     return (
                        <MTG1LanguageSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'mtral':
                     return (
                        <MTG1RALSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTG1MathSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  default:
                     return (
                        <Grade1SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
               }
            case 'Grade 2':
               return (
                  <Grade2SummaryReport
                     assignment_id={props.id}
                     template={props.template}
                     section_id={sectionID}
                     section_desc={sectionDesc}
                     diagnostic_type={props.type}
                     subject={props.subject}
                     subject_id={props.subject_id}
                  />
               );
            case 'Grade 3':
               return (
                  <Grade3SummaryReport
                     assignment_id={props.id}
                     template={props.template}
                     section_id={sectionID}
                     section_desc={sectionDesc}
                     diagnostic_type={props.type}
                     subject={props.subject}
                     subject_id={props.subject_id}
                  />
               );
            case 'Grade 4':
               switch (props.subject_id) {
                  case 'mteng':
                     return (
                        <MTG4EnglishSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTG4MathSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'mtsci':
                     return (
                        <MTG4ScienceSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  default:
                     return (
                        <Grade4SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
               }
            case 'Grade 5':
               return (
                  <Grade5SummaryReport
                     assignment_id={props.id}
                     template={props.template}
                     section_id={sectionID}
                     section_desc={sectionDesc}
                     diagnostic_type={props.type}
                     subject={props.subject}
                     subject_id={props.subject_id}
                  />
               );
            case 'Grade 6':
               return (
                  <Grade6SummaryReport
                     assignment_id={props.id}
                     template={props.template}
                     section_id={sectionID}
                     section_desc={sectionDesc}
                     diagnostic_type={props.type}
                     subject={props.subject}
                     subject_id={props.subject_id}
                  />
               );
            case 'Grade 7':
               switch (props.subject_id) {
                  case 'mteng':
                     return (
                        <MTG7EnglishSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTG7MathSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'mtsci':
                     return (
                        <MTG7ScienceSummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  default:
                     return (
                        <Grade7SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
               }
            case 'Grade 8':
               return (
                  <Grade8SummaryReport
                     assignment_id={props.id}
                     template={props.template}
                     section_id={sectionID}
                     section_desc={sectionDesc}
                     diagnostic_type={props.type}
                     subject={props.subject}
                     subject_id={props.subject_id}
                  />
               );
            case 'Grade 9':
               return (
                  <Grade9SummaryReport
                     assignment_id={props.id}
                     template={props.template}
                     section_id={sectionID}
                     section_desc={sectionDesc}
                     diagnostic_type={props.type}
                     subject={props.subject}
                     subject_id={props.subject_id}
                  />
               );
            case 'Grade 10':
               return (
                  <Grade10SummaryReport
                     assignment_id={props.id}
                     template={props.template}
                     section_id={sectionID}
                     section_desc={sectionDesc}
                     diagnostic_type={props.type}
                     subject={props.subject}
                     subject_id={props.subject_id}
                  />
               );
            case 'Grade 11':
               switch (props.subject_id) {
                  case 'oc':
                     return (
                        <OCG11SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'raw':
                     return (
                        <RAWG11SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'gm':
                     return (
                        <GMG11SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'stats':
                     return (
                        <SPG11SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  default:
                     return (
                        Modal.info({
                           content: 'Report not yet available.',
                           centered: true,
                        })
                     )
               }
            case 'Grade 12':
               switch (props.subject_id) {
                  case 'gp1':
                     return (
                        <GP1G12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'gb1':
                     return (
                        <GB1G12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'gp2':
                     return (
                        <GP2G12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'gb2':
                     return (
                        <GB2G12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'fabm2':
                     return (
                        <FABM2G12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'wrbs':
                     return (
                        <WRBSG12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'ppg':
                     return (
                        <PPGG12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'bf':
                     return (
                        <BFG12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'cw':
                     return (
                        <CWG12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'tnct':
                     return (
                        <TNCTG12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'besr':
                     return (
                        <BESRG12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  case 'ae':
                     return (
                        <AEG12SummaryReport
                           assignment_id={props.id}
                           template={props.template}
                           section_id={sectionID}
                           section_desc={sectionDesc}
                           diagnostic_type={props.type}
                           subject={props.subject}
                           subject_id={props.subject_id}
                        />
                     );
                  default:
                     return (
                        Modal.info({
                           content: 'Report not yet available.',
                           centered: true,
                        })
                     )
               }
         }
      }
   }

   const handleMenuClick = (key, label) => {
      setSectionID(key);
      setSectionDesc(label);
      setShowSummaryReport(true);
   }

   const menuProps = (
      <Menu
         onClick={(e) => handleMenuClick(e.key, (menuItems.find(o => o.key === e.key)).label)}
         items={menuItems}
      />
   );

   const handleDownloadReport = (report_type) => {
      var element = null;
      var reportFileName = "";

      if (report_type === 'individual') {
         element = document.getElementById('individual-report');
         reportFileName = 'Results in ' + subject.toUpperCase() + ' ' + props.template + '.pdf';
      }

      if (report_type === 'summary') {
         element = document.getElementById('summary-report');
         reportFileName = 'Summary Results ' + (props.type === 'pre' ? 'Pre' : 'Post') + ' Test for ' + props.template + '.pdf';
      }

      if (element !== null) {
         var opt = {
            margin: 0.5,
            filename: reportFileName,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
         };

         // New Promise-based usage:
         html2pdf().from(element).set(opt).save();
      }
   }

   return (
      <>
         <div className="dashboard-item-inner3">
            <Table
               rowKey={'uid'}
               loading={listLoading}
               size='small'
               columns={columns}
               dataSource={studentList}
               pagination={false}
               scroll={{
                  x: '500px',
                  y: 'calc(100vh - 18.5rem)',
               }}
               footer={() =>
                  <Row style={{ width: "100%" }}>
                     <Col style={{ width: "70%" }}>{'Total Records: ' + studentList.length}</Col>
                     <Col style={{ width: "30%", textAlign: "right" }}>
                        {
                           // (menuItems.length && props.type === 'post') > 0 &&
                           (menuItems.length) > 0 &&
                           <Dropdown overlay={menuProps} placement='topCenter' trigger={['click']} arrow>
                              <Button className='button-shadow' size='middle' type='primary' shape='round' >
                                 <Space>
                                    Summary Report
                                    <AntDIcons.UpOutlined />
                                 </Space>
                              </Button>
                           </Dropdown>
                        }

                     </Col>
                  </Row>
               }
            />
         </div>

         <Modal
            key='show-diagnostic-report'
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" style={{ minWidth: '30px', maxHeight: '100px' }} /> {"Results in " + (subject !== null ? subject.toUpperCase() : '') + " " + props.template}</div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round", display: 'none' }}
            centered
            destroyOnClose={true}
            open={showReport}
            width='75%'
            style={{ maxWidth: '900px' }}
            // onCancel={() => { setShowReport(false); }}
            // onOk={() => { setShowReport(false); }}
            footer={[
               <Space>
                  <Button
                     shape='round' type="primary"
                     loading={downloadStatus}
                     onClick={() => handleDownloadReport('individual')}
                  >
                     Dowload
                  </Button>
                  <Button shape='round' type='primary' onClick={() => { setShowReport(false); }}>
                     Close
                  </Button>
               </Space>
            ]}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               <div className='section_to_print'>
                  {displayReport(props.template, props.id)}
               </div>
            </div>
         </Modal>

         <Modal
            key='show-diagnostic-summary-report'
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" style={{ minWidth: '30px', maxHeight: '100px' }} /> {"Summary Results  " + (props.type === 'pre' ? 'Pre' : 'Post') + " Test for " + props.template}</div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round", style: { display: 'none' } }}
            centered
            destroyOnClose={true}
            open={showSummaryReport}
            width='75%'
            style={{ maxWidth: '900px' }}
            // onCancel={() => { setShowSummaryReport(false); }}
            // onOk={() => { setShowSummaryReport(false); }}

            footer={[
               <Space>
                  <Button
                     shape='round' type="primary"
                     loading={downloadStatus}
                     onClick={() => handleDownloadReport('summary')}
                  >
                     Dowload
                  </Button>
                  <Button shape='round' type='primary' onClick={() => { setShowSummaryReport(false); }}>
                     Close
                  </Button>
               </Space>
            ]}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               <div className='section_to_print'>
                  {displaySummaryReport()}
               </div>
            </div>
         </Modal>
      </>
   );
}